import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { canAccessPath, useUser } from "../utils/auth"

export default function Template({
  data: {
    main: { frontmatter, html },
    leftColumn,
  },
}) {
  const user = useUser()
  const hasAccess =
    !frontmatter.private || canAccessPath(user, frontmatter.path)

  useEffect(() => {
    if (!hasAccess) {
      navigate("/")
    }
  }, [hasAccess])

  return (
    <Layout
      leftColumn={
        leftColumn && (
          <>
            <h4>{leftColumn.frontmatter.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: leftColumn.html }} />
          </>
        )
      }
    >
      <SEO title={frontmatter.title} />
      <h2>{frontmatter.title}</h2>
      {hasAccess && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!, $leftColumnPath: String) {
    main: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        private
      }
    }

    leftColumn: markdownRemark(frontmatter: { path: { eq: $leftColumnPath } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
